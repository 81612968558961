import React, { useState, FormEvent } from "react";

const subscribeForm = "https://dashboard.mailerlite.com/forms/1020808/126536398884832508/share";

// Function to handle email subscription
const findRequestURL = (mail: string): string => {
  const formURL = subscribeForm.split("/");
  const getNumbers = formURL.filter((item) => Number(item) && item);
  const accountID = getNumbers[0];
  const formID = getNumbers[1];
  const requestURL = `https://assets.mailerlite.com/jsonp/${accountID}/forms/${formID}/subscribe?fields[email]=${mail}`;
  return requestURL;
};

interface HeroProps {
  heading: string;
  message: string;
}

const Hero: React.FC<HeroProps> = ({ heading, message }) => {
  const [mail, setMail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [apiMessage, setApiMessage] = useState<string>("");

  // Handle email subscription
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    fetch(findRequestURL(mail), {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setApiMessage("Thanks for subscribing!");
        } else {
          setApiMessage("Something went wrong, please try again.");
        }
      })
      .catch(() => setApiMessage("Something went wrong, please try again."))
      .finally(() => {
        setLoading(false);
        setMail("");
      });
  };

  // Function to handle smooth scrolling to the "Contact" section
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact"); // Ensure the Contact section has this id
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen relative">
      <img
        src="/assets/hero-picnic-with-table-setting-sunflower-under-tree-shade-datesaverco-dmv-transformed.webp"
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-10" />
      
      {/* Text Content */}
      <div className="flex flex-col items-center justify-center p-5 text-center text-white relative z-20 max-w-full pt-24">
        <p className="text-white text-lg sm:text-1xl font-the-seasons">COMING SOON</p>
        <div className="w-24 h-[1px] bg-white mx-auto mb-6 opacity-50"></div>

        {/* Logo */}
        <div className="max-w-full sm:max-w-[500px] md:max-w-[1000px] mx-auto mb-4">
          <img
            src="/assets/cream-hero-logo.png"
            alt="Hero Logo"
            className="w-full h-auto object-contain"
          />
        </div>
        
        {/* Heading and Message */}
        <p className="font-the-seasons font-bold text-xl sm:text-4xl tracking-wide text-white">
          Unplugged and Unscripted
        </p>
        <p className="font-tt-drugs text-xs sm:text-lg pt-3">
          DC <span className="mx-3 sm:mx-6" style={{ fontFamily: "Arial" }}>|</span>
          MD <span className="mx-3 sm:mx-6" style={{ fontFamily: "Arial" }}>|</span>
          NOVA
        </p>
        
        {/* Email Subscription Form */}
        <form onSubmit={onSubmit} className="mt-4 pt-12 sm:pt-24 relative flex flex-col items-center">
          <input
            type="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="Enter Your Email"
            className={`font-tt-drugs text-lg rounded-full px-4 py-2 mb-3 text-white bg-transparent border border-white placeholder-white text-center w-full sm:w-[350px] md:w-[400px] box-border ${
              mail ? "bg-gray-100 bg-opacity-50" : ""
            }`}
            required
          />
          <p className="font-tt-drugs text-white text-md sm:text-lg mb-4 px-2 italic">
            Be among the first 30 to sign up and get a chance to win a FREE picnic!
          </p>
          <button
            type="submit"
            className="font-tt-drugs md:px-4 md:py-1 py-1 max-w-40 md:max-w-full border border-[#f1f1ef] bg-transparent text-white md:text-lg text-md hover:bg-white hover:text-black transition border-solid w-[80%] sm:w-auto tracking-widest"
            disabled={loading}
          >
            {loading ? "Loading..." : "SAVE MY DATE"}
          </button>

          {/* Displaying message below the Save My Date button */}
          {apiMessage && <p className="mt-2 text-white text-sm">{apiMessage}</p>}
        </form>

        {/* Added "Get 50% Off" and "Pre-book Now" Section */}
        <p className="font-tt-drugs text-white text-md sm:text-lg mt-10 italic">
          Get 50% Off | Pre-Bookings NOW OPEN for OCTOBER – DECEMBER
        </p>
        <button
          className="max-w-48 underline mt-4 md:py-1 md:px-7 py-1 md:max-w-full font-tt-drugs border border-[#f1f1ef] bg-transparent text-white md:text-lg text-md hover:bg-white hover:text-black transition border-solid w-[80%] sm:w-auto tracking-widest"
          onClick={scrollToContact}
        >
          PRE-BOOK NOW
        </button>
      </div>
    </div>
  );
};

export default Hero;

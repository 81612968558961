import React, { useState, useRef } from 'react';
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

interface FormData {
    name: string;
    email: string;
    phoneNumber: string;
    eventDate: string;
    location: string;
    message: string;
    howDidYouHear: string;
    services: string[];
    referralEmails: string;
}

const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phoneNumber: '',
        eventDate: '',
        location: '',
        message: '',
        howDidYouHear: '',
        services: [],
        referralEmails: ''
    });

    const [copied, setCopied] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [messageContent, setMessageContent] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData(prevState => {
            const services = prevState.services.includes(value)
                ? prevState.services.filter(service => service !== value)
                : [...prevState.services, value];
            return { ...prevState, services };
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Format services array into a comma-separated string
        const servicesString = formData.services.join(', ');

        // Google Form submission URL
        const googleFormUrl = `https://docs.google.com/forms/d/e/1FAIpQLScIc4aHlKHjnQrKsSdF3gOnDOhYi4gta0GwQNufL2Q6qK8X-g/formResponse?` +
        `entry.2005620554=${encodeURIComponent(formData.name)}&` + // Full Name
        `entry.1045781291=${encodeURIComponent(formData.email)}&` + // Email
        `entry.728723214=${encodeURIComponent(formData.phoneNumber)}&` + // Phone Number
        `entry.1987412122=${encodeURIComponent(formData.eventDate)}&` + // Event Date
        `entry.1096882091=${encodeURIComponent(formData.location)}&` + // Desired Location
        `entry.694997013=${encodeURIComponent(formData.howDidYouHear)}&` + // How Did You Hear About Us
        `entry.2003628555=${encodeURIComponent(servicesString)}&` + // Services (comma-separated)
        `entry.1694652593=${encodeURIComponent(formData.referralEmails)}&` + // Referral Emails
        `entry.839337160=${encodeURIComponent(formData.message)}`; // Message

        // Submit the data to Google Form via the hidden iframe
        if (iframeRef.current) {
            iframeRef.current.src = googleFormUrl;
        }

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            eventDate: '',
            location: '',
            message: '',
            howDidYouHear: '',
            services: [],
            referralEmails: ''
        });

        // Show confirmation message
        setMessageContent('Your message has been sent successfully.');
        setIsError(false);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
    };

    const handleCopyEmail = () => {
        navigator.clipboard.writeText('datesaverco@gmail.com')
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error('Could not copy text: ', err));
    };

    return (
        <div id="contact" className="bg-[#231f20] text-white">
            {/* Hero Section */}
            <div className="relative h-[350px] lg:h-[400px]">
                <img
                    src="assets/contactus-picnic-wide-angle-datesaverco-DMV-1.webp"
                    alt="For You Picnic"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex flex-col items-center text-center">
                    {/* Logo */}
                    <img src="assets/creamlogo.png" alt="Cream Logo" className="w-20 mt-14 md:w-24 lg:w-40 lg:mt-5 lg:translate-y-5" />
                    
                    {/* Subtitle */}
                    <p className="text-[#f4f1e9] text-sm md:text-base lg:text-xl tracking-wide font-the-seasons mb-9 font-semibold">
                        D A T E &nbsp; S A V E R &nbsp; C O
                    </p>
                    
                    {/* Main Heading */}
                    <h1 className="text-[#f4f1e9] font-the-seasons text-4xl bottom-7 lg:bottom-0 relative md:text-[70px] lg:text-[90px]">
                        WE ARE LISTENING
                    </h1>
                </div>
            </div>

            
            {/* Pre-Booking Section */}
            <div className="max-w-3xl mx-auto py-12 px-4 text-center font-tt-drugs">
                <p className="mt-2 mb-11 text-sm md:text-base">
                    <span className='font-the-seasons text-lg tracking-wide'>We are excited to share our offline spaces with you <span className='font-tt-drugs'>!</span></span><br/><br/>
                    We're taking inquiries case-by-case until our official site is live. Pre-book now for events in October - December. Contact us to secure your date!
                </p>
                <h2 className="text-2xl md:text-3xl font-bold font-the-seasons mb-6">
                    PRE<span className='font-tt-drugs font-extralight'>-</span>BOOKING <span className='font-serif font-extralight'>|</span> HOW IT WORKS
                </h2>

                {/* Steps List */}
                <div className="grid grid-cols-12 gap-4 text-left text-sm md:text-base">
                    {/* Step 1 */}
                    <div className="md:col-span-2 col-span-3 font-bold text-sm md:text-base">STEP 1</div>
                    <div className="col-span-9">
                        Select your desired date + service in the form below.
                    </div>

                    {/* Step 2 */}
                    <div className="md:col-span-2 col-span-3 font-bold text-sm md:text-base">STEP 2</div>
                    <div className="col-span-9">
                        We’ll follow up with details, pricing, availability, and an invoice (including any discounts). Please note, a 50% retainer fee of the total cost will be required to secure your date.
                    </div>

                    {/* Step 3 */}
                    <div className="md:col-span-2 col-span-3 font-bold text-sm md:text-base">STEP 3</div>
                    <div className="col-span-9">
                        Once we receive your initial payment, we’ll confirm your date and start planning your appointment!
                    </div>
                </div>

                {/* CONNECT WITH US */}
                <h2 id='connect' className="text-2xl md:text-3xl font-bold font-the-seasons mb-6 mt-11 md:scroll-mt-20">
                    Connect With Us
                </h2>
                <p className='text-sm md:text-base'>
                    PRE-BOOK for events in OCTOBER - DECEMBER here.                    
                </p>
                <p className='text-sm md:text-base mt-1'>
                    Have any questions? 
                </p>
                
            </div>

            {/* Form Section */}
            <div className="max-w-3xl mx-auto pb-16 px-4 lg:px-0">
                <form className="space-y-6 text-left" onSubmit={handleSubmit}>
                    {/* Full Name */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Full Name<span className='font-tt-drugs'>*</span>:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-50"
                            placeholder="Enter your name"
                            required
                        />
                    </div>

                    {/* Email */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Email<span className='font-tt-drugs'>*</span>:</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-50"
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    {/* Phone Number */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Phone Number:</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className="w-full p-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-50"
                            placeholder="e.g., (240) 716-2616"
                        />
                    </div>

                    {/* Event Date */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Event Date:</label>
                        <input
                            type="text"
                            name="eventDate"
                            value={formData.eventDate}
                            onChange={handleChange}
                            className="w-full p-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-50"
                            placeholder="mm/dd/yyyy"
                        />
                    </div>

                    {/* Desired Location */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Desired Location:</label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className="w-full p-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-50"
                            placeholder="E.g. Gravelly Point, Founders Park"
                        />
                    </div>

                    {/* How did you hear about us? (Dropdown) */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">How did you hear about us?</label>
                        <select
                            name="howDidYouHear"
                            value={formData.howDidYouHear}
                            onChange={handleChange}
                            className="w-full p-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-50"
                        >
                            <option value="" disabled className="text-[#342b29]">Select an option</option>
                            <option value="Instagram" className="text-[#342b29]">Instagram</option>
                            <option value="Facebook" className="text-[#342b29]">Facebook</option>
                            <option value="Tiktok" className="text-[#342b29]">Tiktok</option>
                            <option value="Youtube" className="text-[#342b29]">Youtube</option>
                            <option value="Google" className="text-[#342b29]">Google</option>
                            <option value="Other" className="text-[#342b29]">Other (enter)</option>
                        </select>
                    </div>

                    {/* Which services can we assist you with? (Checkbox) */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Which services can we assist you with?</label>
                        <div className="flex flex-col space-y-2 font-tt-drugs mt-3 text-sm ml-6">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="services"
                                    value="For You Picnic"
                                    onChange={handleServiceChange}
                                    className="form-checkbox text-white"
                                />
                                <span className="ml-2 text-white">For You Picnic</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="services"
                                    value="Glam Photobooth"
                                    onChange={handleServiceChange}
                                    className="form-checkbox text-white"
                                />
                                <span className="ml-2 text-white">Glam Photobooth</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="services"
                                    value="Content Creator"
                                    onChange={handleServiceChange}
                                    className="form-checkbox text-white"
                                />
                                <span className="ml-2 text-white">Content Creator</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="services"
                                    value="Lazy Boy"
                                    onChange={handleServiceChange}
                                    className="form-checkbox text-white"
                                />
                                <span className="ml-2 text-white">Lazy Boy</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="services"
                                    value="VIP Stylized Proposal"
                                    onChange={handleServiceChange}
                                    className="form-checkbox text-white"
                                />
                                <span className="ml-2 text-white">VIP Stylized Proposal</span>
                            </label>
                        </div>
                    </div>

                    {/* Message */}
                    <div>
                        <label className="block text-md font-the-seasons font-semibold">Message:</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full pt-2 pl-2 bg-transparent text-white font-tt-drugs border-b border-white placeholder-white placeholder-opacity-30"
                            placeholder="Curious about anything? Let us know!"
                            required
                        ></textarea>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="bg-[#342b29] text-[#f4f1e9] bg-transparent border px-6 py-1 font-the-seasons hover:bg-white hover:text-black"
                    >
                        Send
                    </button>

                    {/* Message display */}
                    {showMessage && (
                        <p className={`text-sm font-tt-drugs ${isError ? 'text-red-500' : 'text-green-300'}`}>
                            {messageContent}
                        </p>
                    )}
                </form>

                {/* Referral Reward Program Section */}
                <div className="mt-6 pt-2 text-center">
                    <h3 className="text-lg font-the-seasons text-left">Get 50<span className='font-tt-drugs'>%</span> OFF <span className='font-tt-drugs'>|</span> LIMITED TIME <span className='font-tt-drugs'>-</span> BEFORE OUR OFFICIAL OPENING</h3>
                    <p className="mt-5 text-sm font-tt-drugs text-white text-left">
                        As a thank you for being part of our pre-launch, get 50% off by referring 3 friends! 
                        Complete these steps, and we’ll DM you a special code for 50% off!
                    </p>
                    <p className="mt-3 text-sm font-tt-drugs text-white text-left"> 
                        But hurry – this offer is only valid for before our opening, and once it’s gone, it’s gone for good! Follow, share, and comment now to unlock your discount.
                    </p>
                    <p className='text-lg font-the-seasons text-left mt-7'>
                        HOW IT WORKS:
                    </p>
                    <p className='text-lg font-the-seasons text-left mt-5'>
                        INSTAGRAM<span className='font-tt-drugs'>/</span>FB
                    </p>
                    <div className='md:pl-4 text-sm font-tt-drugs text-white text-left'>
                        <p>1. FOLLOW us @datesaverco</p>
                        <p>2. LIKE this post and share it to your story. Don’t forget to TAG us!</p>
                        <p>3. TAG 3 friends in the comments who would love any of our services.</p>
                        <p>4. Tell us which one you’d want to book!</p>
                    </div>
                    <p className='text-lg font-the-seasons text-left mt-5'>
                        TIKTOK
                    </p>
                    <div className='md:pl-4 text-sm font-tt-drugs text-white text-left'>
                        <p>1. FOLLOW us @datesaverco</p>
                        <p>2. DUET or STITCH this video using #DateSaverCo</p>
                        <p>3. COMMENT and TAG friends who’s love this experience. </p>
                    </div>
                </div>
            </div>

            {/* Hidden Iframe for form submission */}
            <iframe
                ref={iframeRef}
                title="hiddenIframe"
                style={{ display: 'none' }}
            ></iframe>

            {/* Footer Section */}
            <div className="py-12 text-left bg-[#f4f1e9] text-[#342b29]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-11">
                    {/* Left Column: Business Info */}
                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold font-the-seasons pb-2">Business Hours</h3>
                        <p>Monday – Friday: 9:00am – 12:00am</p>
                        <p>Saturday – Sunday: 9:00am – 9:00pm</p>

                        <h3 className="mt-6 text-lg font-semibold font-the-seasons pb-2">Website Credits:</h3>
                        <p>Developer: <a href="https://www.linkedin.com/in/ted-liu-49590b184/" target="_blank" className="text-[#342b29] underline">Ted Liu</a></p>
                        <p>Photography: 
                            <a href="https://instagram.com/nvu.films" target="_blank" className="text-[#342b29] underline"> @NVU.Films</a>, 
                            <a href="https://instagram.com/thedmveater" target="_blank" className="text-[#342b29] underline"> @theDMVEater</a>, 
                            Frances Liu
                        </p>
                    </div>

                    {/* Right Column: Social Media Icons & Contact Info */}
                    <div className="flex flex-col items-start space-y-1 font-tt-drugs ml-auto text-left pr-16">
                        <div className="flex space-x-4">
                            <a href="https://instagram.com/datesaverco" target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="w-6 h-6 hover:text-[#ddc6a3]" />
                            </a>
                            <a href="https://facebook.com/profile.php?id=61561750892261" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF className="w-6 h-6 hover:text-[#ddc6a3]" />
                            </a>
                            <a href="https://www.tiktok.com%2F%40datesaverco%" target="_blank" rel="noopener noreferrer">
                                <FaTiktok className="w-6 h-6 hover:text-[#ddc6a3]" />
                            </a>
                            <a href="https://youtube.com/%40datesaverco?si=sD66jorEstXtFCIR" target="_blank" rel="noopener noreferrer">
                                <FaYoutube className="w-6 h-6 hover:text-[#ddc6a3]" />
                            </a>
                            <button onClick={handleCopyEmail} className="focus:outline-none">
                                <AiOutlineMail className="w-6 h-6 hover:text-[#ddc6a3]" />
                            </button>
                        </div>
                        {copied && (
                            <p className="text-[#342b29] text-sm italic pt-5">Email Copied to Clipboard</p>
                        )}

                        <h3 className="text-lg font-semibold mt-6 font-the-seasons pt-5">Join our community</h3>
                        <p className="text-left">Date Saver Co</p>
                        <p className="text-left">DC, Maryland, and Northern Virginia</p>
                        <p className="text-left">datesaverco@gmail.com</p>
                        <p className="text-left">(240) 716-2816</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;

import React, { useState, useEffect } from 'react';

const Carousel = ({ children }: { children: React.ReactNode[] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === children.length - 1 ? 0 : prevSlide + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Auto-slide every 3 seconds
    return () => clearInterval(interval); // Clean up on unmount
  }, [currentSlide]);

  return (
    <div className="relative w-full overflow-hidden rounded-3xl">
      <div
        className="flex transition-transform ease-in-out duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {children.map((child, index) => (
          <div key={index} className="min-w-full">
            {child}
          </div>
        ))}
      </div>

      {/* Indicators */}
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {children.map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 rounded-full bg-white transition-all ${
              currentSlide === index ? 'p-2' : 'bg-opacity-50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const BestStories = () => {
  return (
    <div className="bg-[#f4f1e9] pt-10 pb-10 md:pt-12 md:pb-20 relative">
      <div className="max-w-[85%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 items-center relative z-10 md:pl-11">
        {/* Left Column: Carousel */}
        <div className="space-y-2 md:w-[80%] md:col-span-1 z-10 flex flex-col items-center md:items-start">
          <Carousel>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/contactus-picnic-wide-angle-datesaverco-DMV-1.webp"
                alt="Picnic wide angle"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/dried-pampas-floral-hanging-decor-on-teepee-date-saver-co-dmv.webp"
                alt="Dried pampas floral hanging decor"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/final-couple-belly-laughing-on-knit-lounge-chairs-date-saver-co-picnic-DMV.webp"
                alt="Couple belly laughing on knit lounge chairs"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/final-couple-smiling-admiring-sparkling-wine-sitting-by-low-picnic-table-date-saver-co-picnic-DMV.webp"
                alt="Couple smiling admiring sparkling wine"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/final-couple-smiling-looking-at-INSTAX-photo-while-sitting-on-a-mat-illuminated-by-white-teepee-fabric-datesaverco.webp"
                alt="Couple smiling looking at INSTAX photo"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/hero-picnic-with-table-setting-sunflower-under-tree-shade-datesaverco-dmv-transformed.webp"
                alt="Hero picnic with table setting"
                className="object-cover w-full h-full"
              />
            </div>
          </Carousel>
        </div>

        {/* Right Column: Text Content */}
        <div className="flex flex-col justify-center items-center md:items-start text-center md:pt-0 pt-40 md:right-14 relative md:text-left -mt-40 pb-11 md:mt-0 md:col-span-1 z-10">
          <img
            src="/assets/brownlogo.png"
            alt="Brown Date Saver Co logo"
            className="h-auto w-12 md:w-36 mb-4 md:-translate-x-10 -translate-y-15 md:-translate-y-0"
            loading="lazy"
          />
          <h2 className="text-sm font-the-seasons uppercase text-[#98876d] tracking-wide mb-2">
            DATE SAVER CO
          </h2>
          <p className="text-2xl md:text-5xl font-tt-drugs text-[#51423e] leading-tight">
            Where the best stories aren't posted—<br />
            <br />
            they're lived.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BestStories;

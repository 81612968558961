import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const NavBar: React.FC = () => {
  const [nav, setNav] = useState(false);
  const [color, setColor] = useState("transparent");
  const [textColor, setTextColor] = useState("#ffffff");
  const [logo] = useState("/assets/whitelogo.png");

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 50) {
        setColor("#131313"); // The background color after scrolling
        setTextColor("#404040"); // Text color after scrolling
      } else {
        setColor("transparent"); // Transparent background before scrolling
        setTextColor("#ffffff"); // White text before scrolling
      }
    };
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setNav(false); // Close the mobile menu after clicking
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNav(false); // Close the mobile menu after clicking
  };

  return (
    <div
      style={{ backgroundColor: color }}
      className="fixed left-0 top-0 w-full z-50 ease-in duration-300 hover:bg-[#231f20] hover:bg-opacity-100"
    >
      <div className="relative w-full flex justify-between items-center p-3 text-white font-tt-drugs">
        {/* Save My Date on the left - Hidden on mobile */}
        <div className="flex items-center lg:block hidden">
          <a
            href="#prebook"
            onClick={() => scrollToSection("contact")}
            className="underline text-lg tracking-widest border border-white py-1 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid font-tt-drugs"
          >
            PRE-BOOK NOW
          </a>
        </div>

        {/* Logo in the center, adjusted for mobile */}
        <div className="flex justify-center items-center w-full lg:w-auto lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 translate-x-5">
          <a href="#top" onClick={scrollToTop}>
            <img
              src={logo}
              alt="Date Saver Co Logo"
              width={36}
              height={36}
              className="h-9 w-auto"
            />
          </a>
        </div>

        {/* Right-aligned nav links */}
        <ul className="hidden lg:flex flex-wrap space-x-4 xl:space-x-8 ml-auto pr-4 lg:pr-12 font-tt-drugs text-white text-sm tracking-widest">
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid">
            <a href="#aboutus" onClick={() => scrollToSection("aboutus")}>
              ABOUT US
            </a>
          </li>
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid relative group">
            <a href="#services" className="relative z-10">
              SERVICES
            </a>

            {/* Dropdown Menu for Services */}
            <div className="absolute left-0 mt-2 w-40 bg-[#f4f1e9] text-[#342b29] text-xs shadow-lg opacity-0 group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none group-hover:translate-y-0 transform translate-y-2 transition-all duration-300">
              <a
                href="#fyp"
                onClick={() => scrollToSection("FYP")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                FYP
              </a>
              <a
                href="#glam-photobooth"
                onClick={() => scrollToSection("Glam Photobooth")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                Glam Photobooth
              </a>
              <a
                href="#lazy-boy"
                onClick={() => scrollToSection("Lazy Boy")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                Lazy Boy
              </a>
              <a
                href="#content-creator"
                onClick={() => scrollToSection("Content Creator")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                Content Creator
              </a>
              <a
                href="#vip-stylized-proposal"
                onClick={() => scrollToSection("vip-proposal-design")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                VIP Stylized Proposal
              </a>
            </div>
          </li>
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid">
            <a href="#faq" onClick={() => scrollToSection("FAQ")}>
              FAQ
            </a>
          </li>
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid">
            <a href="#contact" onClick={() => scrollToSection("contact")}>
              CONTACT
            </a>
          </li>
        </ul>

        {/* Mobile Button with added padding */}
        <div onClick={handleNav} className="block lg:hidden z-50 pr-4">
          {nav ? (
            <AiOutlineClose size={20} style={{ color: textColor }} />
          ) : (
            <AiOutlineMenu size={20} style={{ color: textColor }} />
          )}
        </div>

        {/* Mobile Menu */}
        <div
          className={
            nav
              ? "lg:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-[#231f20] text-center ease-in duration-300"
              : "lg:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-[#231f20] text-center ease-in duration-300"
          }
        >
          <ul className="font-tt-drugs text-[#fff4eb] space-y-8 text-lg">
            <li
              onClick={() => scrollToSection("aboutus")}
              className="p-2 hover:text-[#fde39f]"
            >
              About Us
            </li>
            <li
              onClick={() => scrollToSection("services")}
              className="p-2 hover:text-[#fde39f]"
            >
              Services
            </li>
            <li
              onClick={() => scrollToSection("FAQ")}
              className="p-2 hover:text-[#fde39f]"
            >
              FAQ
            </li>
            <li
              onClick={() => scrollToSection("contact")}
              className="p-2 hover:text-[#fde39f]"
            >
              Contact
            </li>
            <li
              onClick={() => scrollToSection("savemydate")}
              className="p-2 hover:text-[#fde39f] border border-white"
            >
              Save My Date
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

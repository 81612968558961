import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

const createIdFromTitle = (title: string) => {
    return title
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/[^\w-]+/g, ''); // Remove special characters
};

interface ServiceSectionProps {
    title: string;
    imageUrl: string;
    description: string;
    whatsInItForYou: string[];
    whatsIncludedInPackages: string[];
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
    title,
    imageUrl,
    description,
    whatsInItForYou,
    whatsIncludedInPackages,
}) => {
    const [isIncludedInPackagesOpen, setIsIncludedInPackagesOpen] = useState(false);

    // Generate ID from the sanitized title
    const sectionId = createIdFromTitle(title);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div
            id={sectionId}
            className="flex flex-col sm:flex-col md:flex-row items-stretch gap-6 pb-4"
            style={{ scrollMarginTop: '80px' }}
        >
            {/* Wrapper for Left and Middle Columns */}
            <div className="flex flex-col md:flex-row w-full md:w-2/5 lg:w-2/4 md:h-[350px] relative pl-0 md:pl-11">
                {/* Left Column: Photo with Overlay */}
                <div className="relative w-full md:w-2/4 flex-shrink-0 overflow-hidden group md:rounded-l-[20px]">
                    {/* Image */}
                    <img
                        src={imageUrl}
                        alt={title}
                        className="w-full h-full object-cover shadow-xl transition duration-300 ease-in-out transform group-hover:scale-110"
                    />
                    {/* Overlay with Title */}
                    <div className="absolute inset-0 flex items-center text-center justify-center bg-black bg-opacity-40">
                        <h3 className="text-3xl sm:text-4xl md:text-5xl text-white font-bold font-the-seasons">
                            {title}
                        </h3>
                    </div>
                </div>

                {/* Middle Column: Service Description */}
                <div
                    className={`w-full md:w-full bg-[#231f20] text-white text-sm p-6 md:p-8 flex flex-col justify-center text-center shadow-lg md:rounded-r-[20px]`}
                >
                    <p
                        className="font-tt-drugs italic mb-4"
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                    <button
                        onClick={() => {
                            const contactSection = document.getElementById('connect');
                            if (contactSection) {
                                contactSection.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}
                        className="mt-8 border font-tt-drugs text-sm border-white hover:bg-white hover:text-[#231f20] py-2 px-2 w-2/5 md:w-1/2 md:mx-auto mx-auto transition border-solid"
                    >
                        SAVE MY DATE
                    </button>
                </div>
            </div>

            {/* Right Column: What's in it for you */}
            <div className="w-full md:w-2/5 lg:w-1/5 flex flex-col text-left text-[#51423e] ml-5 md:ml-0 md:py-0 overflow-auto lg:-mr-1 max-w-80 md:max-w-full">
                <h4 className="text-lg sm:text-xl font-bold font-the-seasons">
                    What’s in it for you:
                </h4>
                <ul className="list-disc ml-5 md:max-w-full mt-2 space-y-2 font-tt-drugs">
                    {whatsInItForYou.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
                {/* Submit Inquiry at the bottom */}
                <p className="mt-4 text-sm font-bold font-tt-drugs uppercase md:ml-0">
                    Submit Inquiry for more info{" "}
                    <button
                        className="underline"
                        onClick={() => {
                            const contactSection = document.getElementById('contact');
                            if (contactSection) {
                                contactSection.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}
                    >
                        BELOW
                    </button>
                </p>
            </div>

            {/* Last Column: What's included in our packages */}
            <div className="w-full md:w-2/5 lg:w-1/5 flex flex-col text-left text-[#51423e] md:py-0 overflow-auto ml-5 md:ml-5 max-w-80 md:max-w-full">
                <h4
                    className="text-lg sm:text-xl font-bold cursor-pointer flex items-center font-the-seasons"
                    onClick={() => setIsIncludedInPackagesOpen(!isIncludedInPackagesOpen)}
                >
                    What’s included in our packages: <FiChevronDown className="ml-2" />
                </h4>
                <div
                    className={`overflow-hidden transition-all duration-500 ${isIncludedInPackagesOpen ? 'max-h-screen' : 'max-h-0'
                        }`}
                >
                    <ul className="list-disc ml-5 mt-2 space-y-2 font-tt-drugs">
                        {whatsIncludedInPackages.map((item, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>  
                        ))}
                        <p className='font-bold'> AND MORE! </p>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServiceSection;

import React from 'react';

const WhoWeAre = () => {
  return (
    <div id="aboutus" className="bg-[#231f20] flex flex-col justify-start items-center text-center text-[#f4f1e9] font-tt-drugs pt-12 pb-36 scroll-mt-20"> 
      <img 
        src="/assets/creamlogo.png" 
        alt="Cream Logo" 
        className="mb-4 w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40"
        loading="lazy"
      />
      <h1 className="text-4xl sm:text-5xl md:text-6xl leading-tight mb-6 sm:mb-8 font-tt-drugs">
        Who We Are
      </h1>
      <p className="text-lg sm:text-xl leading-relaxed w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto font-tt-drugs">
        At Date Saver Co., we’re all about ditching social media hype to focus on <span className='text-[#fde39f] italic'>real connections. </span> We make it easy to enjoy unplugged moments with the people who matter, 
        free from the pressure of scripted posts. Let us handle the details so you can truly connect.
      </p>
    </div>
  );
};

export default WhoWeAre;

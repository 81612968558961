import React from 'react';

const FAQ = () => {
    return (
        <div id='FAQ' className="bg-[#f4f1e9] pb-24 px-4 md:px-0 scroll-pt-6 scroll-mt-16">

            <div className="w-16 sm:w-[90%] h-[1px] bg-[#8d7467] mx-auto mb-20 opacity-30"></div>   

            <div className="md:max-w-full md:mx-auto mx-11 grid grid-cols-1 lg:grid-cols-2 gap-24 items-center text-center md:text-left">
                {/* Text Section */}
                <div className="space-y-8 text-[#342b29] flex flex-col justify-center items-center text-left md:items-start md:pl-[5%] lg:pl-[30%]">
                    <h2 className="text-4xl font-the-seasons">YOUR QUESTIONS, <br /> ANSWERED</h2>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">WHICH MARKETS DO YOU SERVE?</h3>
                        <p className="">
                            We serve DC, Southern Maryland, and Northern Virginia for parties, weddings, corporate events, and more. A travel fee may apply beyond 15 miles. 
                            Message us to bring your plans to life!
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">WHAT HAPPENS AFTER I SIGN UP FOR THE NEWSLETTER?</h3>
                        <p className="">
                            You'll get priority for notifications for deals and booking dates, and a chance to win a FREE picnic! With limited spots, you'll be the first to know 
                            when we launch.
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">50% OFF | REFERRAL REWARD PROGRAM</h3>
                        <p className="">
                            As an early supporter, you can secure 50% off your booking by referring 3 friends. We’re excited to build a community of heartwarming stories 
                            with you while you enjoy your next adventure at half the cost!
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">WHAT IS THE RETAINER FEE FOR?</h3>
                        <p className="">
                            A non-refundable 50% retainer secures your date and lets us plan ahead to ensure a smooth, unforgettable experience! We're here to make your unplugged 
                            stories unforgettable, and this step helps us do just that!
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">CANCELLATION / RESCHEDULING:</h3>
                        <p className="">
                            <span className='underline'>Cancellations</span> with less than a 48 hour notice prior to the event, or bookings made 2 days prior to the event are not eligible for a refund.<br/>
                            <span className='underline'>Rescheduling:</span> Unexpected things happen! Let us know if you need to reschedule, and we’ll work with you to find a new time, subject to availability.
                        </p>
                    </div>

                    <div className="font-tt-drugs">
                        <h3 className="text-lg font-semibold">WEATHER INSURANCE ($95)</h3>
                        <p className="">
                        For an add-on of $95, you can add weather insurance to your booking. This lets you cancel due to bad weather up until the last minute and still get your money back. <br/>
                        With Weather Insurance:
                        Cancel at the last minute: If the weather turns bad and you need to cancel within 48 hours of your event, you’ll get a full refund, including the retainer.
                        $95 weather insurance: Non-refundable. 
                        </p>
                    </div>

                </div>
                {/* Image Section */}
                <div className="flex justify-center lg:justify-start ml-0 md:ml-28">
                    <div className="relative">
                        {/* Background Shape - Hide below lg */}
                        <div className="absolute inset-0 bg-[#ddd6d3] rounded-[30px] md:rounded-[60px] shadow-lg transform translate-x-5 translate-y-5 md:translate-x-20 md:translate-y-16 hidden 2xl:block"></div>
                        
                        <div className='overflow-hidden rounded-[60px]'>
                            <img
                                src="/assets/faq-two-knit-chairs-with-umbrella-between-grazed-by-the-golden-hour-sunlight-date-saver-co-picnic-DMV.webp"
                                alt="Outdoor scene"
                                className="relative rounded-[30px] md:rounded-[60px] shadow-lg w-full md:max-w-sm max-w-xs h-auto transition duration-300 ease-in-out transform hover:scale-110"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;

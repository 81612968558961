import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='bg-[#f4f1e9]'>
      {/* Hero Section */}
      <div className="relative h-[250px] md:h-[500px]">
        <img
          src="assets/arch-with-hanging-pampas-decor-picnic-background-datesaverco-dmv.webp"
          alt="For You Picnic"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex w-full">
          <h1 className="text-white font-the-seasons text-sm md:text-6xl md:text-left text-center flex items-end pl-5 pb-24 md:items-center md:pl-16 md:pt-44">
            Terms<span className='font-tt-drugs font-thin'>&nbsp;&&nbsp;</span>Conditions
          </h1>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

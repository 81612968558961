import React from 'react';
import ServiceSection from './ServiceSection';

const Services = () => {
    const servicesData = [
        {
            title: 'FYP',
            imageUrl: '/assets/FYP-for-you-picnic-table-scape-sunflower-arrangement-date-saver-co-DMV.webp',
            description: "<span class='font-bold'>For You Picnic:</span> Unplug for the Day with a Picnic in Paradise.</br></br><span class='font-bold'>Who is it for?</span><br/>For corporate teams, couples, groups and friends looking to escape screens for a day in nature.",
            whatsInItForYou: [
                'Time-saving research and coordination',
                'Skip the heavy lifting',
                'Unique way to enjoy quality time',
                'No stress about perfect posts, it’s all share worthy',
                'Quick way to impress in a few clicks',
                'None to minimal decisions on your part',
                'On-demand support'
            ],
            whatsIncludedInPackages: [
                'Picnic Table and Table Scape',
                'Pillows and Water-Proof Mats',
                'Personalized Place Cards for Each Guest (Glass Arch Frames)',
                'Non-Alcoholic Sparkling Wine and Water',
                'Seasonal Fresh and Dried Florals',
                'Throw Blanket',
                'Boho Umbrella/Cabana/Teepee',
                'Knit Lounge Chairs',
                'Conversation Starter Cards, Painting Kit',
                'Electric Handheld Fans',
                'Bluetooth Speaker',
                'BONUS: 2X BONUS PERKS',
                'FREE Gift Per Guest'
            ],
        },
        {
            title: 'Glam Photobooth',
            imageUrl: '/assets/glam-photobooth -couple-laughing-while-interacting-with-screen-datesaverco-DMV.webp',
            description: "Old Hollywood Photos. Classy keepsakes.</br></br><span class='font-bold'>Who is it for?</span><br/>For corporate teams, weddings, celebrants, and corporate teams who want to offer elegant photo favors without the fuss.",
            whatsInItForYou: [
                'Achieve the old Hollywood look',
                'Discreet equipment',
                'No waiting or posing for a camera professional',
                'Shoot freely and as much as you like',
                'Elegant and timeless print designs and backdrops',
                'Instant sharing',
                'Cherish private, tangible memories'
            ],
            whatsIncludedInPackages: [
                'Classic Old Hollywood photos',
                'Minimalist wood-finish photobooth',
                'High Resolution Black & White Prints',
                'Premium Pearl Finish Prints',
                'Personalized Minimalist Prints',
                'Premium Back-Drops',
                'Instant Sharing via Wi-Fi',
                'Library Delivery in under 48 Hours',
                '2"x6" or 4"x6" Prints',
                'Unlimited Takes',
                'Photobooth Concierge On-site'
            ],
        },
        {
            title: 'Lazy Boy',
            imageUrl: '/assets/lazy-boy-home-date-night-man-pours-sparkling-wine-into-flute-glass-date-saver-co-DMV.webp',
            description: "An elevated table-scape while you enjoy company.</br></br><span class='font-bold'>Who is it for?</span></br>For groups, corporate teams or couples who crave a cozy table-scape set-up indoors.",
            whatsInItForYou: [
                'Romantic surprise awaits at home',
                'Cozy friends’ gathering',
                'No secret errands needed',
                'Time-saving research and coordination',
                'No heavy lifting or cleanup',
                'Minimal decisions',
                'Maximum relaxation at home'
            ],
            whatsIncludedInPackages: [
                'Low or High Table',
                'Table Scape',
                'Floral and Candle Decor'
            ],
        },
        {
            title: 'Content Creator',
            imageUrl: '/assets/content-creator-for-hire-datesaverco-DMV-3.webp',
            description: "A Top DMV Content Creator documents your event like a guest for the unscripted side of storytelling.</br></br><span class='font-bold'>Who is it for?</span></br>For celebrants, restaurants, corporate teams who wanna document more raw energy.",
            whatsInItForYou: [
                'Organic unscripted touches to your event',
                'Less pressure than big cameras',
                'Go get the margarita - stay engaged no need to use phones',
                '“Short-Style, easy-to-consume edits',
                'Document even the “unserious” moments',
                'We’re perfecting our craft to bring you the relaxed and fun side of authentic storytelling'
            ],
            whatsIncludedInPackages: [
                'Event Content Creator (+ Second Shooter)',
                'Short Style Edits with an iPhone',
                'Key moments, Guest Interactions, and Overall Atmosphere',
                'Highlights or Recap Videos',
                'Live Streaming (optional)',
                'Guest Interviews or Testimonials (optional)'
            ],
        },
        {
            title: 'VIP Stylized Proposal',
            imageUrl: '/assets/stylized-proposal-pampas-decor-datesaverco-DMV-transformed.webp',
            description: "The perfect backdrop + easy-to-follow tips making the lead-up to the BIG YES smooth & memorable.</br></br><span class='font-bold'>Who is it for?</span></br>For romantics who want to pour energy into LOVE - not decorating.",
            whatsInItForYou: [
                'A breathtaking backdrop made easy with minimal customizations which means less brain power for decisions so you can save all your energy for the big question.',
                'We’ll work closely with you to bring your dream engagement to life, even as we build our portfolio.',
                'Minimal customization = minimal decisions',
                'Priority support for questions',
                'Proposal concierge present (optional)',
                'An elegantly styled proposal for a lasting impression',
                'Custom elements reflecting your unique relationship'
            ],
            whatsIncludedInPackages: [
                'A breathtaking backdrop made easy with minimal customizations which means less brain power for decisions so you can save all your energy for the big question.</br></br>We’ll work closely with you to bring your dream engagement to life, even as we build our portfolio. </br></br>'
            ],
        }
    ];

    return (
        <div id="services" className='bg-[#f4f1e9] pt-6 pb-30'>
            <div className="w-16 sm:w-[90%] h-[1px] bg-[#8d7467] mx-auto mt-2 opacity-30 mb-10"></div>

            <h2 className="text-2xl sm:text-3xl md:text-4xl text-[#51423e] mt-8 sm:mt-10 font-bold font-the-seasons text-center">
                <span className="text-[#7a6d4e]"> OUR </span> SERVICES
            </h2>
            <div className="w-16 sm:w-24 h-[1px] bg-[#8d7467] mx-auto mt-2 opacity-50 mb-10"></div>

            {servicesData.map((service, index) => (
                <ServiceSection
                    key={index}
                    title={service.title}
                    imageUrl={service.imageUrl}
                    description={service.description}
                    whatsInItForYou={service.whatsInItForYou}
                    whatsIncludedInPackages={service.whatsIncludedInPackages}
                />
            ))}
        </div>
    );
};

export default Services;
